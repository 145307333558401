import { Menu, Layout as AntdLayout, theme, Flex } from 'antd';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import {
  HomeOutlined,
  ShoppingCartOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import React, { useMemo } from 'react';
import { CartIcon } from './cart/CartIcon';
import { StaticImage } from 'gatsby-plugin-image';

type Props = {
  children: React.ReactNode;
};

const removeSlashes = (url: string) => url.replace(/^\/+|\/+$/g, '');

const items = [
  {
    url: '',
    label: 'Начало',
    icon: <HomeOutlined />,
  },
  {
    url: 'checkout',
    label: 'Поръчка',
    icon: <ShoppingCartOutlined />,
  },
  {
    url: 'contact',
    label: 'Контакти',
    icon: <PhoneOutlined style={{ transform: 'scaleX(-1)' }} />,
  },
].map((item) => ({
  ...item,
  url: item.url ? `/${item.url}` : '/',
  key: item.url ? item.url : '-',
  label: <Link to={item.url ? `/${item.url}` : '/'}>{item.label}</Link>,
}));

export const Layout = ({ children }: Props) => {
  const location = useLocation();
  const activeKey = useMemo(() => {
    const pathname = removeSlashes(location.pathname);
    const item = items.find((item) => removeSlashes(item.url) === pathname);
    return item ? item.key : '-';
  }, [location.pathname]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <AntdLayout>
      <AntdLayout.Header
        className="flex justify-between items-center"
        style={{ backgroundColor: colorBgContainer }}
      >
        <Flex className="gap-2 w-2/3" align="center">
          <Link to="/" style={{ width: 50, height: 50, display: 'block' }}>
            <StaticImage
              src="../images/logo.png"
              alt="logo"
              width={50}
              placeholder="blurred"
            />
          </Link>
          <Menu
            mode="horizontal"
            className="w-full"
            activeKey={activeKey}
            items={items}
          />
        </Flex>
        <CartIcon />
      </AntdLayout.Header>

      {children}
    </AntdLayout>
  );
};

import React from 'react';
import { Layout } from './src/components/Layout';
import { StyleProvider } from './src/components/StyleProvider';
import './src/styles/global.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './src/common/client';
import { GatsbyBrowser } from 'gatsby';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return (
    <StyleProvider>
      <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
    </StyleProvider>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return <Layout>{element}</Layout>;
};

import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from '@reach/router';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useCart } from './useCart';
import { Badge, Popover } from 'antd';
import { CartIconContent } from './CartIconContent';

export const CartIcon = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { total, items, updateQuantity, removeItem } = useCart();
  const totalItems = useMemo(
    () => items.reduce((acc, item) => acc + item.quantity, 0),
    [items],
  );

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      content={
        <CartIconContent
          total={total}
          items={items}
          updateQuantity={updateQuantity}
          removeItem={removeItem}
        />
      }
    >
      <button className="relative">
        <Badge
          className="absolute"
          style={{ top: 3, right: -20 }}
          count={totalItems}
        ></Badge>
        <ShoppingCartOutlined style={{ fontSize: 30 }} />
      </button>
    </Popover>
  );
};
